.content-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 18px rgba(62, 74, 97, 0.12);
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}
.content-card__title {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #050A46;
}
.content-card__button {
    background: unset;
    border: unset;
}

.content-card__operations {
    display: flex;
    gap: 32px;
}